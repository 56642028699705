// main: ../build.less
@import (reference) '../config';

CONFIG[datepicker] {
	--datePicker--font-size: .rem(16)[];
	--datePicker--color-bg: var(--select--color-bg);
	--datePicker--color-text: var(--select--color-text);
	--datePicker--border: var(--select--border-width) solid var(--select--color-border);
	--datePicker--rounding: var(--input--rounding);
	--datePicker--shadow: 
		0 0.1em 0.3em rgba(0,0,0,.2),
		0 0.3em 0.9em rgba(0,0,0,.2);

	--datePicker--header-padding: .rem(6)[] .rem(12)[];
	--datePicker--label-font-size: .rem(14)[];
	--datePicker--label-height: .rem(28)[];
	--datePicker--label-color-text: var(--color-bg-muted);
	
	--datePicker--day-width: .rem(48)[];
	--datePicker--day-height: .rem(32)[];
	
	--datePicker--day-border-width: .rem(1)[];
	--datePicker--day-border-width-focus: .rem(1)[];
	--datePicker--day-color-border: var(--palette--neutral-300);
	--datePicker--day-color-border-focus: var(--color-text);
	
	--datePicker--day-color-bg: var(--color-bg);
	--datePicker--day-color-bg-hover: var(--color-bg-highlight);
	--datePicker--day-color-bg-selected: var(--color-bg-faded);
	--datePicker--day-color-bg-disabled: mix( @color-bg, @color-bg-faded, 70%);

	--datePicker--day-color-text: var(--color-text);
	--datePicker--day-color-text-hover: var(--color-text);
	--datePicker--day-color-text-selected: var(--color-text);
	--datePicker--day-color-text-disabled: var(--color-text-faded);

	--dateInput--stackingContext: var(--viewLayer--popover);
}
.datepicker
{
	&:extend(
		CONFIG[datepicker], 
		CONFIG[select], 
		CONFIG[input]
	);

	width: auto;
	background-color: var(--datePicker--color-bg);
	border-radius: var(--datePicker--rounding);
	border: var(--datePicker--border);
	box-shadow: var(--datePicker--shadow);

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: var(--datePicker--header-padding);
	}
	&__currentMonth { font-weight: bold; }
	&__navButton {
		font-size: .rem(30)[];
		line-height: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	&__columnLabels,
	&__days {
		display: grid;
		grid-template-columns: repeat( 7, var(--datePicker--day-width));
		grid-auto-rows: var(--datePicker--day-height);
		text-align: center;
	}
	&__columnLabels > * {
		font-size: var(--datePicker--label-font-size);
		line-height: var(--datePicker--label-height);
		text-transform: uppercase;
		color: var(--datePicker--label-color-text);
	}

	&__day {
		font-size: var(--datePicker--font-size);
		line-height: calc(var(--datePicker--day-height) - var(--datePicker--day-border-width) * 2);
		border-top: var(--datePicker--day-border-width) solid var(--datePicker--day-color-border);
		border-right: var(--datePicker--day-border-width) solid var(--datePicker--day-color-border);
		color: var(--datePicker--day-color-text);
		cursor: pointer;

		&:nth-child(7n) { border-right: none; }

		&:hover {
			background-color: var(--datePicker--day-color-bg-hover);
			color: var(--datePicker--day-color-text-hover)
		}
		&.is\:focus {
			border: var(--datePicker--day-border-width-focus) solid var(--datePicker--day-color-border-focus);
		}

		&.is\:selected {
			font-weight: bold;
			cursor: default;
			background-color: var(--datePicker--day-color-bg-selected);
			color: var(--datePicker--day-color-text-selected);
		}

		&--otherMonth {
			background-color: var(--datePicker--day-color-bg-disabled);
			//color: var(--color-text-faded);
		}

		&.is\:disabled, .is\:disabled:hover  {
			background-color: var(--datePicker--day-color-bg-disabled);
			color: var(--datePicker--day-color-text-disabled);
		}
	}
}
